import { AxiosResponse } from 'axios';
import { LoadDataFiltersArg } from '../../components/ConfigurableChart/ChartTypes';
import { get, post, postReturnResponse } from '../../helpers/axiosWrapper';
import {
  IElecRegisterData,
  IGasRegisterData,
  ICustomersFilterItem,
  IMeterItemData,
  IAccountsFilterItem,
  ISiteData,
} from '../../types/account';
import { REVERSED_DATE_FORMAT_HYPHEN, ISO_DATE_TIME_FORMAT_UTC } from '../constants';
import moment from 'moment';

/* --------------------------- OPUS --------------------------- */
// /GASMONTHLYCONSUMPTION
export interface GasMonthlyConsumptionResult {
  accountsFilters: IAccountsFilterItem[];
  customerFilters: ICustomersFilterItem[];
  gasMonthlyConsumption: Api.IGasMonthlyConsumptionData[];
}
export const getGasMonthlyConsumptionByDateRangeOrLast12Months = async (
  url: string | undefined,
  context: any,
  essCustomerId: string,
  filters: LoadDataFiltersArg = {},
  startDate: string | null = null,
  endDate: string | null = null
): Promise<Common.IResult<GasMonthlyConsumptionResult>> => {
  const headers: any = {};

  if (filters.essAccountId) {
    headers.EssAccountId = filters.essAccountId;
  }
  if (startDate && endDate) {
    headers.startDate = startDate;
    headers.endDate = endDate;
  }
  return await get(
    url + `/gasmonthlyconsumption/gasmonthlyconsumptionbydaterangeorlast12months/${essCustomerId}`,
    { headers },
    context
  ).catch(error => console.error(`Failed to load gas monthly consumption for date`, error));
};

// /ELECMONTHLYCONSUMPTION
export interface ElecMonthlyConsumptionResult {
  accountsFilters: IAccountsFilterItem[];
  customerFilters: ICustomersFilterItem[];
  elecMonthlyConsumptions: Api.IGasMonthlyConsumptionData[];
}
export const getElectMonthlyConsumptionByDateRangeOrLast12Months = async (
  url: string | undefined,
  context: any,
  essCustomerId: string,
  filters: LoadDataFiltersArg = {},
  startDate: string | null = null,
  endDate: string | null = null
): Promise<Common.IResult<ElecMonthlyConsumptionResult>> => {
  const headers: any = {};

  if (filters.essAccountId) {
    headers.EssAccountId = filters.essAccountId;
  }
  if (startDate && endDate) {
    headers.startDate = startDate;
    headers.endDate = endDate;
  }
  return await get(
    url + `/elecmonthlyconsumption/elecmonthlyconsumptionbydaterangeorlast12months/${essCustomerId}`,
    { headers },
    context
  ).catch(error => console.error(`Failed to load electricity monthly consumption for date`, error));
};

// /STREAMING
export const streamGasMonthlyConsumptionByDateRangeOrLast12Months = async (
  url: string | undefined,
  context: any,
  essCustomerId: string,
  essAccountId: number,
  endDate: string,
  startDate: string,
  responseFormat: 'csv' | 'xml'
): Promise<string> => {
  const data: any = {
    responseFormat,
    essCustomerId,
    essAccountId,
    startDate,
    endDate,
  };

  return await post(url + `/streaming/gasmonthlygraphdata12monthsorbydaterange`, { data }, context).catch(error =>
    console.error(`Failed to download gas monthly consumption for date`, error)
  );
};

export const streamElectMonthlyConsumptionByDateRangeOrLast12Months = async (
  url: string | undefined,
  context: any,
  essCustomerId: string,
  essAccountId: number,
  endDate: string,
  startDate: string,
  responseFormat: 'csv' | 'xml'
): Promise<string> => {
  const data: any = {
    responseFormat,
    essCustomerId,
    essAccountId,
    startDate,
    endDate,
  };

  return await post(url + `/streaming/elecmonthlygraphdata12monthsorbydaterange`, { data }, context).catch(error =>
    console.error(`Failed to download electricity monthly consumption for date`, error)
  );
};

// TODO - API needs to return IResult - move to this when it does
// export const loadSitesByAccountId = (
//   url: string | undefined,
//   context: any,
//   essAccountId: string
// ): Promise<Common.IResult<ISiteData[]>> => {
//   return get(`${url}/site/sitesbyaccountid/${essAccountId}`, {}, context);
// };

export const loadSitesByAccountId = (
  url: string | undefined,
  context: any,
  essAccountId: string
): Promise<ISiteData[]> => {
  return get(`${url}/site/sitesbyaccountid/${essAccountId}`, {}, context);
};

// /GASMETER
export const loadGasMetersBySiteId = (
  url: string | undefined,
  context: any,
  siteId: string
): Promise<Common.IResult<IMeterItemData[]>> => {
  return get(`${url}/gasmeter/gasmetersbysite/${siteId}`, {}, context);
};

export const loadGasMeterReads = (
  url: string | undefined,
  context: any,
  siteId: number,
  meterSerialNumber: string
): Promise<Common.IResult<IGasRegisterData>> => {
  return get(`${url}/gasmeter/gasmeterreads/${siteId}/${meterSerialNumber}`, {}, context);
};

// /ELECMETER
export const loadElecMeterReads = (
  url: string | undefined,
  context: any,
  siteId: number,
  meterSerialNumber: string
): Promise<Common.IResult<IElecRegisterData>> => {
  return get(`${url}/elecmeter/elecmeterreads/${siteId}/${meterSerialNumber}`, {}, context);
};

// /ELECMETERINGSYSTEM
// TODO - API needs to return IResult - move to this when it does
// export const loadElecMetersBySiteId = (
//   url: string | undefined,
//   context: any,
//   siteId: number
// ): Promise<Common.IResult<IMeterItemData[]>> => {
//   return get(`${url}/elecmeteringsystem/elecmetersbyesssiteid/${siteId}`, {}, context);
// };
export const loadElecMetersBySiteId = (
  url: string | undefined,
  context: any,
  siteId: string
): Promise<IMeterItemData[]> => {
  return get(`${url}/elecmeteringsystem/elecmetersbyesssiteid/${siteId}`, {}, context);
};

// GenMeter
export const loadGenMeterReads = (
  url: string | undefined,
  context: any,
  meterSerialNumber: string
): Promise<Common.IResult<IElecRegisterData>> => {
  return get(`${url}/elecgenerationread/elecgenerationreadsbymeterserialnumber/${meterSerialNumber}`, {}, context);
};

/* --------------------------- DRAX --------------------------- */
// NOTE: `startDate` should be older than 'endDate`. This is important because in some SSP APIs it's reversed
export const loadConsumptionDrax = (
  url: string | undefined,
  context: any,
  entityId: string,
  startDate: string,
  endDate: string,
  groupingInterval: Api.GroupingInterval,
  entityType: Api.EntityType = 'Customer',
  requestType: Api.HHRequestType,
  customerId?: string
): Promise<Common.IResult<Api.IDraxConsumptionResponse>> => {
  var consumptionUrl = `${url}/consumption/consumption?EntityType=${entityType}&EntityId=${entityId}&StartDate=${startDate}&EndDate=${endDate}&GroupingInterval=${groupingInterval}&CustomerAccountReference=${customerId}`;
  if (requestType && (groupingInterval === 'Day' || groupingInterval === 'HalfHour')) {
    consumptionUrl += `&requestType=${requestType}`;
  } else if (groupingInterval === 'Month') {
    consumptionUrl += `&requestType=HalfHourly`;
  }

  return get(consumptionUrl, {}, context);
};

// This API currently accepts only "Site" entity type
export const loadHalfHourlyConsumptionDrax = (
  url: string | undefined,
  context: any,
  entityId: string,
  startDate: string,
  endDate: string,
  entityType: Api.EntityType = 'Customer',
  customerId: string,
  requestType: Api.HHRequestType
): Promise<Common.IResult<Api.IDraxHHConsumptionResponse>> => {
  var consumptionUrl = `${url}/consumption/hh-consumption?EntityType=${entityType}&EntityId=${entityId}&StartDateTime=${startDate}&EndDateTime=${endDate}&CustomerAccountReference=${customerId}`;
  if (requestType) {
    consumptionUrl += `&requestType=${requestType}`;
  }
  return get(consumptionUrl, {}, context);
};

export const getHHSagaProcess = (
  url: string | undefined,
  customerAccountReferences: string | string[],
  requestType: Api.HHRequestType,
  context: any
): Promise<Common.IResult<Array<Api.IHHSagaProcessingData>>> => {
  const headers: Api.IHHSagaRequest = {
    customerAccountReferences,
    requestType,
  };
  return get(`${url}/consumption/hh-saga-processes/`, { headers }, context);
};

export const getHHConsumptionData = (
  url: string | undefined,
  customerAccountReference: string,
  mpans: Array<string>,
  startDate: any,
  endDate: any,
  requestType: Api.HHRequestType,
  context: any
): Promise<null | AxiosResponse<any>> => {
  const data: Api.IHHConsumptionRequest = {
    customerAccountReference,
    mpans,
    startDateTime: startDate.format(ISO_DATE_TIME_FORMAT_UTC),
    endDateTime: moment(endDate.format(REVERSED_DATE_FORMAT_HYPHEN)).endOf('day').format(ISO_DATE_TIME_FORMAT_UTC),
    requestType,
  };
  return post(`${url}/consumption/hh-download`, { data }, context);
};

export const getBrokerTpiReportData = (url: string | undefined, context: any): Promise<null | AxiosResponse<any>> => {
  return postReturnResponse(`${url}/tpis/generatebrokerreport`, {}, context);
};
