import moment from 'moment';
import { TagManager } from '../../components/utils/analytics/TagManager';
import { get, patch, put, requestFileResponseTypeBlob, requestZipFile } from '../../helpers/axiosWrapper';
import { isDraxBrand } from '../../utils/common';
import { globalApiParams } from './globals';

export const invoicesElectricity = async (
  url: string | undefined,
  context: any,
  essCustomerId: string,
  searchTerm: string,
  fuelType: string,
  page: number,
  sort?: Common.ISort
): Promise<any> => {
  const headers: Api.IInvoicesParams = {
    searchTerm, // 0 - No filtering
    fuelType,
    sortField: sort?.field,
    sortDir: sort?.order,
    page,
    pageSize: globalApiParams.pageSize,
  };

  const apiUrl = 'invoice/gaselecinvoicedetailsbyesscustomerid';

  if (isDraxBrand) {
    return await get(`${url}/${apiUrl}/${essCustomerId}?getFilters=false`, { headers }, context);
  } else {
    return await get(`${url}/${apiUrl}/${essCustomerId}`, { headers }, context);
  }
};

export const invoicesElectricityEDW = async (
  url: string | undefined,
  context: any,
  essCustomerId: string,
  accountId: string,
  invoiceNumber: string,
  siteId: string,
  mpan: string,
  date: string,
  dateFrom: string,
  dateTo: string,
  fuelType: string,
  page: number,
  sort?: Common.ISort
): Promise<any> => {
  const apiUrl = 'ers/v1/revenue-transactions';

  const mpanQuery = mpan ? `&mpan-core=${mpan}` : '';
  const siteQuery = siteId ? `&site-reference=${siteId}` : '';

  // Start of the current month 2 years ago
  const transactionPeriodStartDate = `${moment().year() - 2}-${moment().format('MM')}-01`;
  let transactionPeriodStartDateQuery = `&transaction-date-from=${transactionPeriodStartDate}`;
  const dateFromToQuery =
    dateFrom.length > 0 && dateTo.length > 0
      ? `&transaction-date-from=${dateFrom}&transaction-date-to=${dateTo}`
      : transactionPeriodStartDateQuery;

  const sortQuery = sort ? `&sort=${sort?.field}=${sort?.order}` : '';

  return await get(
    `${url}/${apiUrl}?customer-account-reference=${essCustomerId}${sortQuery}${mpanQuery}${siteQuery}${dateFromToQuery}&page=${page}&page-size=${globalApiParams.pageSize}`,
    {},
    context
  );
};

export const invoicesGas = async (
  url: string | undefined,
  context: any,
  essCustomerId: string,
  searchTerm: string,
  fuelType: string,
  page: number,
  sort?: Common.ISort
): Promise<any> => {
  let headers: Api.IInvoicesParams = {
    searchTerm, // 0 - No filtering
    sortField: sort?.field,
    fuelType,
    sortDir: sort?.order,
    page,
    pageSize: globalApiParams.pageSize,
  };

  return await get(url + `/invoice/gaselecinvoicedetailsbyesscustomerid/${essCustomerId}`, { headers }, context);
};

export const renewableStatements = async (
  url: string | undefined,
  context: any,
  essCustomerId: string,
  essAccountId: string,
  essSiteId: string,
  invoiceReference: string,
  page: number,
  sort?: Common.ISort
): Promise<any> => {
  let headers: Api.IRenewableStatementsParams = {
    sortField: sort?.field,
    sortDir: sort?.order,
    page,
    pageSize: globalApiParams.pageSize,
    essAccountId,
    essSiteId,
    invoiceReference,
  };

  return await get(url + `/adhocinvoice/renewablestatements/${essCustomerId}`, { headers }, context);
};

export const putSiteReference = async (
  url: string | undefined,
  context: any,
  siteId: number,
  customerSiteReference: string
): Promise<any> => {
  let data: any = { essSiteID: siteId, customerSiteReference };
  if (customerSiteReference.length === 0) {
    data = { essSiteID: siteId };
  }
  return await put(url + '/site/sitereference', data, context);
};

export const patchSiteReference = async (
  url: string | undefined,
  context: any,
  siteId: number,
  customerSiteReference: string
): Promise<any> => {
  let data: any = { 'customer-site-reference': customerSiteReference };
  if (customerSiteReference.length === 0) {
    data = { 'customer-site-reference': siteId };
  }
  return await patch(url + `/site/sites/${siteId}`, data, context);
};

export const statements = async (url: string | undefined, context: any): Promise<any> => {
  let headers = {};
  return await get(url + '/v1/home/statements', { headers }, context);
};

export const downloadInvoices = async (
  url: string | undefined,
  data: any,
  context: any,
  essAccountId: string
): Promise<any> => {
  return await requestZipFile(
    `${url}/invoice/revenue-transactions-documents?document-type=Copies`,
    data,
    context,
    `Invoice download for ${essAccountId}`,
    'Unfortunately, your Invoice download failed. Please resubmit the request via the invoices page'
  );
};

export const downloadInvoiceBackingData = async (url: string | undefined, data: any, context: any): Promise<any> => {
  return await requestFileResponseTypeBlob(
    `${url}/ersgatewayadapter/invoice/transactionchargedetailsreportdownload`,
    data,
    context
  );
};

export const getChargeDetailsForLedgerRevenueTransaction = async (
  url: string | undefined,
  context: any,
  transactionId: number,
  page: number,
  pageSize: number
): Promise<Api.IRevenueTransactionChargeDetails> => {
  return await get(
    `${url}/ers/v1/revenue-transactions-charge-details?revenue-transactions-details=${transactionId}&page=${page}&page-size=${pageSize}`,
    {},
    context
  );
};
