import styled from 'styled-components';

import * as colors from '../../common/styles/colors';

export const StyledInvoiceDataRowWrapper = styled.div`
  table {
    background-color: ${colors.white};
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    width: 100%;
    tr {
      th {
        font-weight: normal;
      }
    }

    th {
      padding-left: 15px;
    }

    .rowHeading {
      th {
        border-bottom: 1px solid black;
      }

      .spacer {
        border-bottom: none;
      }
    }

    .rowdata {
      td {
        background-color: ${colors.white} !important;
        border-bottom: 1px solid ${colors.midGrey};
        border-bottom-style: dotted;

        border-radius: 0 !important;
        padding: 10px 0 10px 15px;
      }

      .spacer {
        border-bottom: none;
      }
    }

    .totalRow {
      border-top: 4px solid ${colors.white} !important;
      font-weight: bold;

      td {
        padding-left: 15px;
        background-color: ${colors.midGrey} !important;

        border-radius: 0 !important;
      }
    }

    .finalTotalRow {
      border-top: 4px solid ${colors.white} !important;
      font-weight: bold;

      td {
        padding-left: 15px;
        background-color: ${colors.midGrey} !important;
      }
    }
  }
`;
