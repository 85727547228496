// Components
import { useContext, useRef } from 'react';
import { ConfigurableChart } from '.';
import { toast } from 'react-toastify';
import convertArrayToCSV from 'convert-array-to-csv';

import XMLJs from 'xml-js';

// Types
import { EssAccountsTypes } from '../../types/account';
import {
  PickedMonthDate,
  MpanFilterItem,
  LoadDataFiltersArg,
  DownloadEntitiesIds,
  LoadDataFunctionResult,
  LoadDRAXDataFunctionOptions,
  LoadOPUSDataFunctionOptions,
} from './ChartTypes';
import { Ranges } from '../../common/components/DateFilterModal';

// Api
import {
  loadConsumptionDrax,
  loadHalfHourlyConsumptionDrax,
  getGasMonthlyConsumptionByDateRangeOrLast12Months,
  getElectMonthlyConsumptionByDateRangeOrLast12Months,
} from '../../common/api/dataApi';

import { getMetersTwoYearsOld } from '../../common/api/meterReadsApi';
// Context
import { useAuthState } from '../../providers/authProvider';
import { UserDetailContext, UserDetailContextProps } from '../../context/userDetail/userDetailContext';

// Utils
import { getDateFromRange } from '../../helpers/dateHelper';
import { downloadCSV } from '../../helpers/downloadCSV';
import { downloadXML } from '../../helpers/downloadXML';
import { isDraxBrand, isOpusBrand } from '../../utils/common';
import { fillEmptyMonths } from './ChartUtils';
import { TagManager } from '../utils/analytics/TagManager';
import { useState } from 'react';
import { formatDateReverse } from '../utils/date/date';
import { sortMpansFilterItem } from '../../utils/sortFilters';
import { downloadMpanGraphHalfHourlyData } from '../../common/api/consumptionApi';

type IndividualSitesGraphProps = {
  handleSelectedMpanChanged?: Function;
};

const defaultProps = {};

export function IndividualSitesGraph(props: IndividualSitesGraphProps) {
  // Ref
  const draxConsumptionResponseRef = useRef<Api.IDraxConsumptionResponse | Api.IDraxHHConsumptionResponse | null>(null);

  // Context
  const authContext = useAuthState();
  const { userDetail } = useContext<UserDetailContextProps>(UserDetailContext);

  const [allowDownloadHalfHourly, setAllowDownloadHalfHourly] = useState<boolean>(false);

  // Handlers
  const loadElecDataSSP = (customerId: string, filters: LoadDataFiltersArg, startDate?: string, endDate?: string) =>
    getElectMonthlyConsumptionByDateRangeOrLast12Months(
      process.env.REACT_APP_API_URL,
      authContext,
      customerId,
      filters,
      startDate,
      endDate
    ).then(response => {
      let result: LoadDataFunctionResult = {
        accountsFilters: [],
        customerFilters: [],
        sitesFilters: [],
        data: [],
      };

      if (response?.success) {
        if (response.data?.accountsFilters) {
          result.accountsFilters = response.data.accountsFilters;
        }
        if (response.data?.customerFilters) {
          result.customerFilters = response.data.customerFilters;
        }
        if (response.data?.elecMonthlyConsumptions) {
          result.data = response.data?.elecMonthlyConsumptions;
        }
      } else {
        const message = 'API error calling: elecmonthlyconsumptionbydaterangeorlast12months';
        console.error(message);
        toast.error(message, { position: 'bottom-right' });
      }

      return result;
    });

  const loadGasDataSSP = (customerId: string, filters: LoadDataFiltersArg, startDate?: string, endDate?: string) =>
    getGasMonthlyConsumptionByDateRangeOrLast12Months(
      process.env.REACT_APP_API_URL,
      authContext,
      customerId,
      filters,
      startDate,
      endDate
    ).then(response => {
      let result: LoadDataFunctionResult = {
        accountsFilters: [],
        customerFilters: [],
        sitesFilters: [],
        data: [],
      };

      if (response?.success) {
        if (response.data?.accountsFilters) {
          result.accountsFilters = response.data.accountsFilters;
        }
        if (response.data?.customerFilters) {
          result.customerFilters = response.data.customerFilters;
        }
        if (response.data?.gasMonthlyConsumption) {
          result.data = response.data?.gasMonthlyConsumption;
        }
      } else {
        const message = 'API error calling: gasmonthlyconsumptionbydaterangeorlast12months';
        console.error(message);
        toast.error(message, { position: 'bottom-right' });
      }

      return result;
    });

  const loadOPUSData: any = async (options: LoadOPUSDataFunctionOptions) => {
    let result: LoadDataFunctionResult = {
      accountsFilters: [],
      customerFilters: [],
      sitesFilters: [],
      data: [],
    };
    const { type, customerId, filters = {}, startDate, endDate } = options;

    if (type === EssAccountsTypes.Electricity) {
      const elecResult = await loadElecDataSSP(customerId, filters, startDate, endDate);

      result = { ...result, ...elecResult };
    } else if (type === EssAccountsTypes.Gas) {
      const gasResult = await loadGasDataSSP(customerId, filters, startDate, endDate);

      result = { ...result, ...gasResult };
    } else {
      console.error(`Received unknown product type: "${type}"`);
    }
    return result;
  };

  const loadDRAXData: any = async (options: LoadDRAXDataFunctionOptions) => {
    let result: LoadDataFunctionResult = {
      accountsFilters: [],
      customerFilters: userDetail.customerAccounts.map(customer => ({
        essCustomerID: customer.essAccountID,
        customerName: customer.accountName,
      })),
      sitesFilters: [],
      data: [],
    };
    const { endDate, entities, startDate, isInitialLoad, groupingInterval } = options || {};

    try {
      let consumptionResponse = null;
      if (!isInitialLoad) {
        // Load consumption
        if (groupingInterval === 'HalfHour') {
          consumptionResponse = await loadHalfHourlyConsumptionDrax(
            process.env.REACT_APP_API_URL,
            authContext,
            entities.mpanId,
            endDate,
            startDate,
            'MpanCore',
            entities.customerId,
            entities.requestType
          );
        } else if (groupingInterval === 'Day' || groupingInterval === 'Month') {
          consumptionResponse = await loadConsumptionDrax(
            process.env.REACT_APP_API_URL,
            authContext,
            entities.mpanId,
            endDate,
            startDate,
            groupingInterval,
            'MpanCore',
            entities.requestType,
            entities.customerId
          );
        }
      }

      // Consumption response
      if (consumptionResponse !== null) {
        if (consumptionResponse?.success) {
          draxConsumptionResponseRef.current = consumptionResponse.data;
          if (Array.isArray(consumptionResponse.data?.consumptions)) {
            const [consumption] = consumptionResponse.data?.consumptions || [];

            if ('intervalStart' in consumption) {
              result.data = consumptionResponse.data.consumptions as Api.IDraxConsumptionData[];
            } else {
              const [data] = consumptionResponse.data.consumptions as [Api.IDraxHHConsumptionData];

              result.data = data.periods;
            }
          }

          if (groupingInterval === 'Month') {
            result.data = fillEmptyMonths(startDate, endDate, (result.data as Api.IDraxConsumptionData[]) || []);
          }
          // Save consumption data to result object
        } else if (!consumptionResponse?.success) {
          const message = 'API error calling: elecmonthlyconsumptionbydaterangeorlast12months';

          console.error(message);
          toast.error(message, { position: 'bottom-right' });
        }
      }
    } catch (error) {
      console.error('Tried to fetch DRAX consumption data but caught error.', error);
      toast.error('DRAX consumption unexpected error occurred', { position: 'bottom-right' });
    } finally {
      return result;
    }
  };

  const groupMpans = (data: Array<Api.IMeter>) => {
    const mpans: { [key: string]: Array<Api.IMeter> } = {};

    data.map(el => {
      const mpanCore = el['mpan-core'];

      if (Object.keys(mpans).includes(mpanCore.toString())) {
        mpans[mpanCore].push(el);
      } else {
        mpans[mpanCore] = [el];
      }
      return mpans;
    });

    return mpans;
  };

  const getRequestType = (mpan: Api.IMeter): Api.HHRequestType => {
    const { 'hh-or-nhh': hhOrNhh, 'smart-meter': smartMeter, 'meter-type': meterType } = mpan;

    var requestType: Api.HHRequestType = 'HalfHourly';

    if (hhOrNhh === 'HH') {
      requestType = 'HalfHourly';
    } else if (smartMeter === true && (meterType === 'NCAMR' || meterType === 'RCAMR' || meterType === 'RCAMY')) {
      requestType = 'AutomaticMeterRead';
    } else if (smartMeter === true && meterType.startsWith('S')) {
      requestType = 'Smart';
    }
    return requestType;
  };

  const formatMpan = (mpan: Api.IMeter): MpanFilterItem => {
    const requestType = getRequestType(mpan);

    var result: MpanFilterItem = {
      mpan: `${mpan['mpan-core']}`,
      hhOrNhh: mpan['hh-or-nhh'],
      meterType: mpan['meter-type'],
      smartMeter: mpan['smart-meter'],
      requestType,
    };
    return result;
  };

  const getMpanTime = (mpan: Api.IMeter) => new Date(mpan['configuration-end-date']).getTime();

  const loadMpans = async (customerId: string) => {
    let result: Array<MpanFilterItem> = [];
    const response = await getMetersTwoYearsOld(process.env.REACT_APP_API_URL, authContext, customerId);

    if (response) {
      const mpans: { [key: string]: Array<Api.IMeter> } = groupMpans(response);

      Object.keys(mpans).map(id => {
        let matchedMpan = mpans[id].find(el => el['configuration-end-date'] === null);

        if (matchedMpan) {
          result.push(formatMpan(matchedMpan));
        } else {
          const maxDateMpan = mpans[id].reduce((a, b) => (getMpanTime(a) > getMpanTime(b) ? a : b));
          result.push(formatMpan(maxDateMpan));
        }
        return result;
      });
    }

    var resultSorted = sortMpansFilterItem(result);
    return resultSorted;
  };

  const chooseTagEvents = (fileType: string, pickedMonth?: PickedMonthDate, mpanId?: string) => {
    let event = '';

    if (fileType === 'csv') {
      event = 'Choose download format csv (usage graph)';
    } else if (fileType === 'xml') {
      event = 'Choose download format xml (usage graph)';
    }
    if (event && pickedMonth) {
      TagManager.pushData({ event, selectedDate: pickedMonth, mpanId });
    }
  };

  const formatHHData = (
    data: Array<Api.IDraxHHConsumptionData>,
    customerId: string,
    entityId: string,
    entityType: string
  ) => {
    let formattedData: any = [];
    data.forEach(({ mpanCore, date, periods }) => {
      let sum = 0;
      periods.forEach(period => (sum += period.consumptionValue));

      let periodValues = Array<number>();
      periods.forEach(value => {
        periodValues.push(value.consumptionValue);
      });

      let data: any = {
        MPAN: mpanCore,
        Date: date,
        Total: sum.toFixed(1),
      };

      for (let i = 0; i < 50; i++) {
        data[`HH${i + 1}`] = periodValues[i];
      }
      formattedData.push(data);
    });

    return formattedData;
  };

  const convertDataToReport = (fileType: string, data: Array<any>) => {
    let result = null;

    if (fileType === 'csv') {
      const csv = convertArrayToCSV(data);
      result = csv.toString();
    } else if (fileType === 'xml') {
      let consumptionCollection: Consumption.IConsumptionCollection = {
        consumption: {
          elecMonthlyConsumption: data,
        },
      };

      result = XMLJs.js2xml(consumptionCollection, { compact: true, spaces: 2 });
    }
    return result;
  };

  const downloadReport = (
    fileType: string,
    pickedMonth: PickedMonthDate | undefined,
    fileName: string,
    result: string
  ) => {
    if (fileType === 'csv') {
      downloadCSV(result, fileName);
    } else if (fileType === 'xml') {
      downloadXML(result, fileName);
    }
  };

  const downloadFile = async (
    fileType: 'csv' | 'xml',
    dataType: EssAccountsTypes,
    date: Ranges,
    entitiesIds: DownloadEntitiesIds,
    pickedMonth?: PickedMonthDate
  ) => {
    try {
      let [rangeStartDate, rangeEndDate] = getDateFromRange(date);
      let isHHData = false;

      if (isOpusBrand) {
        // TODO: Implement for OPUS
      } else if (isDraxBrand && !!draxConsumptionResponseRef.current) {
        let arrayToConvert: any[] = [];
        const { customerId, mpanId, requestType } = entitiesIds;

        chooseTagEvents(fileType, pickedMonth, mpanId);

        if (!pickedMonth) {
          if ('groupingInterval' in draxConsumptionResponseRef.current) {
            // Non half-hourly data
            const { endDate, entityId, startDate, entityType, consumptions, groupingInterval } =
              draxConsumptionResponseRef.current;
            let nonHHData = consumptions || [];

            if (groupingInterval === 'Month') {
              nonHHData = fillEmptyMonths(startDate, endDate, consumptions || []);
            }

            // Fill out all items with additional data
            arrayToConvert = nonHHData.map(consumption => ({
              customerId: customerId,
              actualConsumption: consumption.actualConsumption,
              estimatedConsumption: consumption.estimatedConsumption,
              totalConsumption: consumption.totalConsumption,
              intervalEnd: consumption.intervalEnd,
              intervalStart: consumption.intervalStart,
              consumptionSource: consumption.сonsumptionSource,
              entityId: entityId,
              entityType: entityType,
              groupingInterval: groupingInterval,
              endDate: endDate || rangeStartDate,
              startDate: startDate || rangeEndDate,
            }));

            rangeStartDate = startDate;
            rangeEndDate = endDate;
          } else {
            // Half-hourly data
            const { entityId, startTimeDate, endTimeDate } = draxConsumptionResponseRef.current;

            await downloadMpanGraphHalfHourlyData(
              process.env.REACT_APP_API_URL,
              authContext,
              'MpanCore',
              entityId,
              startTimeDate,
              endTimeDate,
              customerId,
              fileType,
              requestType
            );
          }
        } else if (pickedMonth) {
          // TODO - Don't think this is called anymore
          // Half-hourly data
          if (!mpanId || !pickedMonth?.begin || !pickedMonth?.end) return;

          const response = await loadHalfHourlyConsumptionDrax(
            process.env.REACT_APP_API_URL,
            authContext,
            mpanId,
            pickedMonth.end,
            pickedMonth.begin,
            'MpanCore',
            customerId,
            requestType
          );

          if (!response.data) {
            const message = `No data available for the selected month. Please select a different month to download.`;
            toast.info(message);
            return;
          }

          const { endTimeDate, startTimeDate, entityId, entityType, consumptions } = response.data;
          let hhData = consumptions || [];

          rangeStartDate = formatDateReverse(startTimeDate);
          rangeEndDate = formatDateReverse(endTimeDate);
          isHHData = true;

          // Fill out all items with additional data
          arrayToConvert = formatHHData(hhData, customerId, entityId, entityType);
        }

        if (date !== 'Daily') {
          const result = convertDataToReport(fileType, arrayToConvert);

          if (result !== null) {
            const fileName = `${customerId}-${mpanId}-Elec-${isHHData ? 'HH-' : ''}Data-${rangeStartDate}${
              rangeEndDate ? '-' + rangeEndDate : ''
            }`;

            // Download file
            downloadReport(fileType, pickedMonth, fileName, result);
          }
        }
      }
    } catch (error) {
      const message = `Failed to download individual sites graph consumption data as "${fileType}" file`;

      console.error(message, error);
      toast.error(message);
    }
  };

  return (
    <ConfigurableChart
      type='sites'
      loadMpansFilterData={loadMpans}
      loadOPUSData={loadOPUSData}
      loadDRAXData={loadDRAXData}
      downloadFile={downloadFile}
      enableSwitchViewsOnClick={isDraxBrand}
      isMpanFilterEnabled
      allowDownloadHalfHourly={allowDownloadHalfHourly}
      setAllowDownloadHalfHourly={setAllowDownloadHalfHourly}
      askForCustomerAccount={false}
      handleSelectedMpanChanged={props.handleSelectedMpanChanged}
    />
  );
}

IndividualSitesGraph.defaultProps = defaultProps;
