// Modules
import React, { useContext, useEffect } from 'react';
import moment from 'moment';
import useState from 'react-usestateref';

// Components
import { SelectedCustomer } from './Filters';
import { Ranges } from '../../common/components/DateFilterModal';
import { ConfigurableChartView } from './ConfigurableChartView';

// Context
import { UserDetailContext, UserDetailContextProps } from '../../context/userDetail/userDetailContext';
import { GlobalContext, GlobalDetailContextProps } from '../../context/globalContext/globalContext';

// Utils
import { parseConsumptionData, parsePeriodConsumptionData } from './ChartUtils';
import { OnBarClick } from '../../common/components/Chart';
import { getDateFromRange, getMonthString } from '../../helpers/dateHelper';
import { isDraxBrand, isOpusBrand } from '../../utils/common';
import { TagManager } from '../utils/analytics/TagManager';

// Types
import * as ChartTypes from './ChartTypes';
import { BarControllerChartOptions, ChartDataset } from 'chart.js';
import { SelectDateResult } from '../../common/components/DateFilterModal';
import { EssAccountsTypes, IAccountsFilterItem, ICustomersFilterItem } from '../../types/account';

// Styles
import { draxDarkBlue, successLight } from '../../common/styles/colors';
import { sortCustomers } from '../../utils/sortFilters';

const defaultProps = {
  type: 'overview',
  isMpanFilterEnabled: false,
  allowDownloadHalfHourly: false,
  enableSwitchViewsOnClick: false,
};

export function ConfigurableChart(props: ChartTypes.ConfigurableChartProps) {
  const initialSiteIndex = -1;

  // Refs
  const endDateRef = React.useRef<string | null>('');
  const beginDateRef = React.useRef<string | null>('');
  const selectedCustomerIdRef = React.useRef<string>();

  // Props
  const {
    type,
    loadOPUSData,
    loadDRAXData,
    downloadFile,
    loadMpansFilterData,
    isMpanFilterEnabled,
    allowDownloadHalfHourly,
    enableSwitchViewsOnClick,
    setAllowDownloadHalfHourly = () => {},
  } = props;

  // Context
  const { userDetail, isCorpBroker } = useContext<UserDetailContextProps>(UserDetailContext);
  const globalContext = useContext<GlobalDetailContextProps>(GlobalContext);

  // State
  const [dataType, setDataType, dataTypeRef] = useState<EssAccountsTypes>(EssAccountsTypes.Electricity);

  const [isLoadingData, setIsLoadingData] = React.useState(false);
  const [isLoadingConsumptionData, setIsLoadingConsumptionData] = React.useState(false);
  const [isLoadingMeterData, setIsLoadingMeterData] = React.useState(false);

  const [selectedRange, setSelectedRange, selectedRangeRef] = useState<Ranges>(Ranges['1year']);
  const [selectedCustomerIndex, setSelectedCustomerIndex] = React.useState<number>(-1);
  const [selectedAccountIndex, setSelectedAccountIndex] = React.useState<number>(-1);
  const [customersFilters, setCustomersFilters] = React.useState<ICustomersFilterItem[]>([]);
  const [accountsFilters, setAccountsFilters] = React.useState<IAccountsFilterItem[]>([]);
  const [opusConsumptionData, setOpusConsumptionData] = React.useState<
    Api.IGasMonthlyConsumptionData[] | Api.IElecMonthlyConsumptionData[]
  >([]);
  const [, setDraxConsumptionData, draxConsumptionDataRef] = useState<Api.IDraxConsumptionData[] | Api.IDraxHHPeriod[]>(
    []
  );
  const [mpanFilters, setMpanFilters, mpanFiltersRef] = useState<ChartTypes.MpanFilterItem[]>([]);
  const [mpanOptions, setMpanOptions] = useState<ChartTypes.IMpanOption[]>([]);

  const [selectedMpanIndex, setSelectedMpanIndex, selectedSiteIndexRef] = useState(initialSiteIndex);
  const [_, setSelectedMpan, selectedMpanRef] = useState<ChartTypes.IMpanData | null>(null);

  useEffect(() => {
    if (isLoadingMeterData || isLoadingConsumptionData) {
      setIsLoadingData(true);
      console.log('setIsLoadingData true');
    } else {
      setIsLoadingData(false);
      console.log('setIsLoadingData false');
    }
  }, [isLoadingMeterData, isLoadingConsumptionData]);

  useEffect(() => {
    const { dataSelectedCustomer, dataSelectedCustomerIndex } = globalContext.globalDetail;

    // Trigger customer selection if customer was selected in other chart filter
    if (dataSelectedCustomer && dataSelectedCustomerIndex !== undefined) {
      onCustomerSelect(dataSelectedCustomer, dataSelectedCustomerIndex);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalContext.globalDetail.dataSelectedCustomer, globalContext.globalDetail.dataSelectedCustomerIndex]);

  useEffect(() => {
    if (userDetail?.essCustomerId && !isCorpBroker()) {
      setSelectedMpanIndex(-1);
      setSelectedMpan(null);
      setMpanFilters([]);

      if (userDetail.customerAccounts && userDetail.customerAccounts.length === 1) {
        getConsumptionData(selectedRange, { customerId: userDetail?.essCustomerId }, dataType, isDraxBrand);
      }
    }
    // If user doesn't have electricity, switch data type to gas
    if (userDetail?.loggedInDetails && userDetail.loggedInDetails.hasGas) {
      setDataType(EssAccountsTypes.Gas);
    }

    selectedCustomerIdRef.current = userDetail?.essCustomerId;
    // TODO:  Might want to remove this and revisit this problem later
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetail?.essCustomerId]);

  useEffect(() => {
    // Update selectedCustomerId reference value when new customerId selected from dropdown
    if (!!customersFilters.length && selectedCustomerIndex >= 0) {
      selectedCustomerIdRef.current = customersFilters[selectedCustomerIndex]?.essCustomerID;
    }
    // TODO:  Might want to remove this and revisit this problem later
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCustomerIndex]);

  // Handlers
  const selectDataType = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, value: EssAccountsTypes) => {
    setDataType(value);
    setSelectedAccountIndex(-1);
    setSelectedCustomerIndex(-1);
    setSelectedMpanIndex(-1);

    getConsumptionData(selectedRangeRef.current, { customerId: selectedCustomerIdRef.current }, value);
  };

  const handleDateSelect = (date: SelectDateResult, option: Ranges, isDaily?: boolean, isMonthly?: boolean) => {
    if (date.type === 'range') {
      const formattedDate = { begin: '', end: '' };

      if (isMonthly) {
        formattedDate.begin = date.begin!.format('YYYY-MM-DD');
        formattedDate.end = date.end!.format('YYYY-MM-DD');
      } else if (isDaily) {
        formattedDate.begin = date.begin!.utcOffset(0, true).format();
        formattedDate.end = date.end!.utcOffset(0, true).format();
      }

      if (isMonthly || isDaily) {
        // Save values to refs for 'Monthly' and 'Daily' ranges
        beginDateRef.current = formattedDate.begin;
        endDateRef.current = formattedDate.end;
      } else if (!isMonthly && !isDaily) {
        // Reset refs for other ranges
        beginDateRef.current = null;
        endDateRef.current = null;
      }
      setSelectedRange(option);
      getConsumptionData(option, { customerId: selectedCustomerIdRef.current, date: formattedDate }, dataType);
    }
  };

  const onCustomerSelect = (customer: SelectedCustomer, index: number) => {
    setSelectedCustomerIndex(index);
    setSelectedMpanIndex(-1);
    getConsumptionData(selectedRange, { customerId: customer.value }, dataType);
    loadMpans(customer.value);
    setAllowDownloadHalfHourly(false);

    globalContext.setGlobalDetail({
      ...globalContext.globalDetail,
      dataSelectedCustomer: customer,
      dataSelectedCustomerIndex: index,
    });
  };

  const setSelectedMpanData = (mpan: ChartTypes.IMpanOption) => {
    let mpanData = undefined;

    if (mpan) {
      const smartMeter = 'S2CDE, S2CD, S2ADE, S2AD, S2A, S1, S';
      let isSmart: boolean = false;

      if (mpan?.meterType) {
        isSmart = smartMeter.includes(mpan.meterType);
      }

      mpanData = {
        ...mpan,
        isSmart,
        isHH: mpan.hhOrNhh === 'HH',
      };

      if (mpanData.isHH) {
        setAllowDownloadHalfHourly(true);
      }
      setSelectedMpan(mpanData);
    }

    if (props.handleSelectedMpanChanged) {
      props.handleSelectedMpanChanged(mpanData);
    }
  };

  const onMpanSelect = (mpan: ChartTypes.IMpanOption, index: number) => {
    let date;

    setSelectedMpanIndex(index);
    setSelectedMpanData(mpan);

    if (index === -1) return;

    if (beginDateRef.current && endDateRef.current) {
      date = { begin: beginDateRef.current, end: endDateRef.current };
    }

    getConsumptionData(
      selectedRangeRef.current,
      { customerId: selectedCustomerIdRef.current, mpanId: mpan.display, date },
      dataType
    );
  };

  const handleAccountSelect = (option: Common.IOption, index: number) => {
    const value: unknown = option.value;

    setSelectedAccountIndex(index);
    getConsumptionData(selectedRangeRef.current, { customerId: selectedCustomerIdRef.current }, dataType, {
      essAccountId: value as number,
    });
  };

  // Helpers
  const loadMpans = async (customerId: string) => {
    if (!isMpanFilterEnabled || typeof loadMpansFilterData !== 'function') return;

    setIsLoadingMeterData(true);
    const mpans = await loadMpansFilterData(customerId);
    setIsLoadingMeterData(false);
    setMpanFilters(mpans);

    if (mpans.length === 1) {
      const el: ChartTypes.MpanFilterItem = mpans[0];
      const option: ChartTypes.IMpanOption = {
        display: '',
        value: el.mpan,
        hhOrNhh: el.hhOrNhh,
        meterType: el.meterType,
        smartMeter: el.smartMeter,
      };
      setSelectedMpanIndex(0);
      onMpanSelect(option, 0);
    }
  };

  const getOPUSConsumptionData = (
    startDate: string,
    endDate: string,
    customerId: string,
    type: EssAccountsTypes,
    filters?: ChartTypes.LoadDataFiltersArg
  ) => {
    const loadDataConfig = {
      type: type || dataType,
      customerId,
      startDate,
      filters,
      endDate,
    };

    setOpusConsumptionData([]);
    setIsLoadingData(true);
    loadOPUSData(loadDataConfig).then(result => {
      const { accountsFilters, customerFilters, sitesFilters: newSitesFilters } = result;
      const data = result.data as Api.IElecMonthlyConsumptionData[] | Api.IGasMonthlyConsumptionData[];

      setIsLoadingData(false);
      setAccountsFilters(accountsFilters);
      setCustomersFilters(customerFilters);
      setMpanFilters(newSitesFilters);
      if (customerFilters.length === 1 && `${customerFilters[0]?.essCustomerID}` === userDetail.essCustomerId) {
        setSelectedCustomerIndex(0);
      }
      if (data.length === 1) {
        setSelectedAccountIndex(0);
      }
      if (newSitesFilters.length === 1) {
        setSelectedMpanIndex(0);
      }
      setOpusConsumptionData(data);
    });
  };

  const getDRAXConsumptionData = (
    startDate: string,
    endDate: string,
    entities: { customerId: string; mpanId: string; requestType: Api.HHRequestType },
    groupingInterval: Api.GroupingInterval,
    isInitialLoad: boolean = false,
    dontUpdateIfNoData: boolean = false
  ) => {
    const loadDataConfig = {
      entities,
      groupingInterval,
      startDate,
      endDate,
      isInitialLoad,
    };
    let promise = null;

    setDraxConsumptionData([]);

    if (type === 'overview' || (type === 'sites' && (isInitialLoad || entities.mpanId))) {
      setIsLoadingConsumptionData(true);
      promise = loadDRAXData(loadDataConfig);
    }

    if (promise instanceof Promise) {
      setDraxConsumptionData([]);
      promise.then(result => {
        if (!dontUpdateIfNoData || (dontUpdateIfNoData && result.data?.length)) {
          if (result.customerFilters?.length) {
            const customersFiltered = sortCustomers(result.customerFilters);
            setCustomersFilters(customersFiltered);
          }

          setDraxConsumptionData(result.data as Api.IDraxConsumptionData[]);
          if (
            result.customerFilters.length === 1 &&
            `${result.customerFilters[0]?.essCustomerID}` === userDetail.essCustomerId
          ) {
            setSelectedCustomerIndex(0);
          }
        }
        setIsLoadingConsumptionData(false);
      });
    }
  };

  const getConsumptionData = (range: Ranges, ...args: any) => {
    let [startDate, endDate] = getDateFromRange(range);

    if (isOpusBrand) {
      const [{ customerId }, type, filters] = args;

      return getOPUSConsumptionData(startDate, endDate, customerId, type, filters);
    } else if (isDraxBrand) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [{ customerId, mpanId }, type, isInitialLoad, dontUpdateIfNoData] = args;
      const selectedMpanId = mpanId || mpanFiltersRef.current[selectedSiteIndexRef.current]?.mpan;
      const requestType = mpanFiltersRef.current[selectedSiteIndexRef.current]?.requestType;
      const entities = { customerId, mpanId: selectedMpanId, requestType };
      let groupingInterval: Api.GroupingInterval = 'Month';

      // For 'Monthly' and 'Daily' ranges use date from refs
      if (beginDateRef.current !== null && endDateRef.current !== null) {
        if (range === Ranges.monthly) {
          endDate = endDateRef.current;
          startDate = beginDateRef.current;
          groupingInterval = 'Day';
        } else if (range === Ranges.daily) {
          endDate = endDateRef.current;
          startDate = beginDateRef.current;
          groupingInterval = 'HalfHour';
        }
      }

      return getDRAXConsumptionData(startDate, endDate, entities, groupingInterval, isInitialLoad, dontUpdateIfNoData);
    }
  };

  const downloadReport = async (option: Common.IOption) => {
    const mpanId = mpanFiltersRef.current?.[selectedSiteIndexRef.current]?.mpan;
    const requestType = mpanFiltersRef.current?.[selectedSiteIndexRef.current]?.requestType;
    const customerId = selectedCustomerIdRef.current!;
    const essAccountId: unknown = accountsFilters?.[selectedAccountIndex]?.essAccountID;
    const fileType = option.value as 'csv' | 'xml';

    if (fileType === 'csv') {
      TagManager.pushData({ event: 'download.csv' });
    } else if (fileType === 'xml') {
      TagManager.pushData({ event: 'download.xml' });
    }

    downloadFile(fileType, dataType, selectedRangeRef.current, {
      customerId,
      essAccountId: essAccountId as number,
      mpanId,
      requestType,
    });
  };

  const getCorrectData = (sortByDate?: boolean) => {
    if (isOpusBrand) {
      return opusConsumptionData;
    } else if (isDraxBrand) {
      const draxData = draxConsumptionDataRef.current;

      if (sortByDate) {
        return draxData.sort(
          (a: Api.IDraxConsumptionData | Api.IDraxHHPeriod, b: Api.IDraxConsumptionData | Api.IDraxHHPeriod) => {
            let aDate = 0;
            let bDate = 0;

            if ('intervalStart' in a && 'intervalStart' in b) {
              aDate = moment(a.intervalStart).valueOf();
              bDate = moment(b.intervalStart).valueOf();
            } else if ('periodStart' in a && 'periodStart' in b) {
              aDate = moment(a.periodStart).valueOf();
              bDate = moment(b.periodStart).valueOf();
            }

            return aDate - bDate;
          }
        );
      }
      return draxData;
    }
    return [];
  };

  const onBarClick: OnBarClick = index => {
    if (!enableSwitchViewsOnClick) {
      return;
    }

    if (selectedMpanRef.current?.requestType === 'HalfHourly') {
      if (!selectedMpanRef.current?.isHH && type === 'sites') {
        return;
      }
    }

    let dateRange: Ranges | undefined;
    let date: { begin: string; end: string } = { begin: '', end: '' };
    const data = getCorrectData(true);
    const selectedItem = data[index];

    if (selectedMpanRef.current?.requestType === 'HalfHourly') {
      if (
        'сonsumptionSource' in selectedItem &&
        selectedItem.сonsumptionSource !== 'HH' &&
        selectedItem.сonsumptionSource !== 'Both'
      )
        return;
    }

    switch (selectedRangeRef.current) {
      case Ranges['1year']:
      case Ranges['2years']:
      case Ranges['6months']:
        if ('intervalStart' in selectedItem) {
          date.begin = selectedItem.intervalEnd;
          date.end = selectedItem.intervalStart;
        }
        dateRange = Ranges['monthly'];
        break;
      case Ranges['monthly']:
        dateRange = Ranges['daily'];
        if ('intervalStart' in selectedItem) {
          date.begin = moment(selectedItem.intervalEnd).endOf('day').utcOffset(0, true).format();
          date.end = selectedItem.intervalStart;
        }
        break;
      default:
        break;
    }

    if (date.begin && date.end) {
      endDateRef.current = date.end;
      beginDateRef.current = date.begin;
    }

    if (dateRange) {
      setSelectedRange(dateRange);
      getConsumptionData(
        dateRange,
        { customerId: selectedCustomerIdRef.current, date },
        dataTypeRef.current,
        false,
        true
      );
    }
  };

  let consumptionData = getCorrectData();

  let chartConsumptionData: Consumption.IConsumptionChartData;
  if (selectedRange === Ranges['daily']) {
    chartConsumptionData = parsePeriodConsumptionData(consumptionData);
  } else {
    chartConsumptionData = parseConsumptionData(consumptionData, selectedRangeRef.current, type === 'overview');
  }

  const datasets: (ChartDataset & BarControllerChartOptions)[] = [
    {
      data: chartConsumptionData.actualData,
      skipNull: true,
    },
    {
      data: chartConsumptionData.estimatedData,
      backgroundColor: draxDarkBlue,
      skipNull: true,
    },
    {
      data: chartConsumptionData.monthToDateData,
      backgroundColor: successLight,
      skipNull: true,
    },
  ];

  const tabs = [];

  if (userDetail?.loggedInDetails?.hasElectricity) {
    tabs.push(EssAccountsTypes.Electricity);
  }
  if (userDetail?.loggedInDetails?.hasGas) {
    tabs.push(EssAccountsTypes.Gas);
  }

  const accountsOptions = accountsFilters.map(account => {
    let icon;

    switch (dataType) {
      case EssAccountsTypes.Electricity:
        icon = 'electricity';
        break;
      case EssAccountsTypes.Gas:
        icon = 'gas-02';
        break;
      default:
        break;
    }
    return {
      display: `${account.essAccountID} - ${account.accountName}`,
      value: `${account.essAccountID}`,
      icon,
    };
  });

  const customersOptions = customersFilters.map(customer => ({
    display: `${customer.essCustomerID} - ${customer.customerName}`,
    value: `${customer.essCustomerID}`,
  }));

  useEffect(() => {
    const options = mpanFilters.map((el, i) => ({
      display: el.mpan,
      value: `${el.mpan}`,
      hhOrNhh: el.hhOrNhh,
      meterType: el.meterType,
      smartMeter: el.smartMeter,
      requestType: el.requestType,
    }));
    setMpanOptions(options);
  }, [mpanFilters]);

  const previouslySelectedDate = { begin: beginDateRef.current, end: endDateRef.current };
  let dateString = '';

  if (selectedRangeRef.current === Ranges['daily'] || selectedRangeRef.current === Ranges['monthly']) {
    const [item] = consumptionData;

    if (item) {
      if ('period' in item) {
        const date = moment(item.periodStart);

        if (selectedRange === Ranges['daily']) {
          dateString = date.format('DD/MM/YYYY');
        } else if (selectedRange === Ranges['monthly']) {
          let monthString = getMonthString(date.month() + 1);

          dateString = `${monthString} ${date.get('year')}`;
        }
      } else if ('intervalStart' in item) {
        const date = moment(item.intervalStart);

        if (selectedRange === Ranges['daily']) {
          dateString = date.format('DD/MM/YYYY');
        } else if (selectedRange === Ranges['monthly']) {
          let monthString = getMonthString(date.month() + 1);

          dateString = `${monthString} ${date.get('year')}`;
        }
      }
    }
  } else {
    dateString = 'Month';
  }

  const handleDateSelectorFilterChange = (option: Common.IOption) => {
    if (!option) return;

    const result: SelectDateResult = { type: 'range' };

    var rangeOption: Ranges = Ranges['2years'];

    switch (option.value) {
      case '24Months': {
        rangeOption = Ranges['2years'];
        result.begin = moment();
        result.end = moment().subtract(23, 'months').startOf('month');
        break;
      }
      case '12Months': {
        rangeOption = Ranges['1year'];
        result.begin = moment();
        result.end = moment().subtract(11, 'months').startOf('month');
        break;
      }
      case '6Months': {
        rangeOption = Ranges['6months'];
        result.begin = moment();
        result.end = moment().subtract(5, 'months').startOf('month');
        break;
      }
      case 'Monthly': {
        rangeOption = Ranges['monthly'];
        break;
      }
      case 'Daily': {
        rangeOption = Ranges['daily'];
        break;
      }
      default: {
        result.type = 'error';
        console.error(`DateFilterModal: Unhandled date range: "${option}"`);
        break;
      }
    }

    handleDateSelect(result, rangeOption);
  };

  return (
    <ConfigurableChartView
      type={type}
      tabs={tabs}
      labels={chartConsumptionData.labels}
      datasets={datasets}
      dataType={dataType}
      mpanMprnOptions={mpanOptions}
      consumptionDate={dateString}
      selectedRange={selectedRangeRef.current}
      isLoadingData={isLoadingData}
      accountsOptions={accountsOptions}
      customersOptions={customersOptions}
      selectedMpanIndex={selectedMpanIndex}
      sitesFilterEnabled={isMpanFilterEnabled!}
      selectedAccountIndex={selectedAccountIndex}
      selectedCustomerIndex={selectedCustomerIndex}
      previouslySelectedDate={previouslySelectedDate}
      allowDownloadHalfHourly={allowDownloadHalfHourly}
      onBarClick={onBarClick}
      isCorpBroker={isCorpBroker}
      onMpanSelect={onMpanSelect}
      selectDataType={selectDataType}
      downloadReport={downloadReport}
      onCustomerSelect={onCustomerSelect}
      handleDateSelect={handleDateSelect}
      handleAccountSelect={handleAccountSelect}
      isSmartMeter={selectedMpanRef.current?.isSmart}
      handleDateSelectorFilterChange={handleDateSelectorFilterChange}
      askForCustomerAccount={props.askForCustomerAccount}
    />
  );
}

ConfigurableChart.defaultProps = defaultProps;
