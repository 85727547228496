import { useEffect, useState } from 'react';
import { StyledInvoiceDataRowWrapper } from './InvoiceDataRow.styles';

type InvoiceDataRowProps = {
  data: Api.IRevenueTransactionChargeDetails;
};

export const InvoiceDataRow = (props: InvoiceDataRowProps) => {
  const [totals, setTotals] = useState<{ vat: string; total: number }[]>([]);

  useEffect(() => {
    const result = calculateTotals();
    setTotals(result);
  }, [props.data]);

  function setDecimalPlaces(arr: any, min: number, max: number) {
    return arr.toLocaleString(undefined, {
      minimumFractionDigits: min,
      maximumFractionDigits: max,
    });
  }

  const calculateTotals = (): { vat: string; total: number }[] => {
    const vatTotals: Record<string, number> = {};

    const data = filteredData();
    if (data && data.length > 0) {
      for (const value of data) {
        const vat = value['charge-line-vat-rate'];
        const natValue = value['charge-line-vat-amount'];

        if (vat in vatTotals) {
          vatTotals[vat] += natValue;
        } else {
          vatTotals[vat] = natValue;
        }
      }
    }

    const result = Object.entries(vatTotals).map(([vat, total]) => ({ vat, total }));
    return result;
  };

  const sumOfVatAmount = () => {
    var total = 0;
    const data = filteredData();
    if (data && data.length > 0) {
      data.map((item: Api.IRevenueTransactionChargeDetail) => {
        total += item['charge-line-vat-amount'];
      });
    }
    return setDecimalPlaces(total, 2, 2);
  };

  const filteredData = (): Array<Api.IRevenueTransactionChargeDetail> => {
    let results: Array<Api.IRevenueTransactionChargeDetail> = [];
    props.data.results.map((item: Api.IRevenueTransactionChargeDetail) => {
      if (item['charge-line-net-amount'] !== 0) {
        results.push(item);
      }
    });
    return results;
  };

  const renderVatRows = () => {
    if (totals.length === 1) {
      return (
        <tr className='finalTotalRow'>
          <td>Total VAT at {totals[0].vat}</td>
          <td colSpan={7}></td>
          <td>£{sumOfVatAmount()}</td>
        </tr>
      );
    }

    if (totals.length > 1) {
      let result = totals.map((total: any) => {
        return (
          <tr className='totalRow'>
            <td>VAT at {total.vat}</td>
            <td colSpan={7}></td>
            <td>£{setDecimalPlaces(total.total, 2, 2)}</td>
          </tr>
        );
      });

      result.push(
        <tr className='finalTotalRow'>
          <td>Total VAT amount</td>
          <td colSpan={7}></td>
          <td>£{sumOfVatAmount()}</td>
        </tr>
      );
      return result;
    }
  };

  return (
    <StyledInvoiceDataRowWrapper>
      <table>
        <thead>
          <tr className='rowHeading'>
            <th>Charge line description</th>
            <th className='spacer'></th>
            <th>Rates</th>
            <th className='spacer'></th>
            <th>Unit(s)</th>
            <th className='spacer'></th>
            <th>Consumption charge</th>
            <th className='spacer'></th>
            <th>Net amount (£)</th>
          </tr>
        </thead>
        <tbody>
          {filteredData().map((item: Api.IRevenueTransactionChargeDetail) => {
            return (
              <tr className='rowdata'>
                <td>{item['charge-line-description']}</td>
                <td className='spacer'></td>
                <td>{setDecimalPlaces(item['charge-line-rate'], 2, 21)}</td>
                <td className='spacer'></td>
                <td>{item['charge-line-unit-label']}</td>
                <td className='spacer'></td>
                <td>
                  {item['charge-consumption']
                    ? setDecimalPlaces(item['charge-consumption'], 2, 21)
                    : item['charge-consumption']}
                </td>
                <td className='spacer'></td>
                <td>{setDecimalPlaces(item['charge-line-net-amount'], 2, 2)}</td>
              </tr>
            );
          })}

          {renderVatRows()}
        </tbody>
      </table>
    </StyledInvoiceDataRowWrapper>
  );
};
