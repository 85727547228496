import { getSingleFile } from '../../helpers/axiosWrapper';

export const downloadHHreport = async (
  url: string | undefined,
  context: any,
  requestId: string,
  fileType: 'XML' | 'CSV'
): Promise<any> => {
  const headers: any = {
    fileType,
  };
  return await getSingleFile(url + `/consumption/hh-download-report/${requestId}`, { headers }, context);
};

export const downloadMpanGraphHalfHourlyData = async (
  url: string | undefined,
  context: any,
  entityType: Api.EntityType = 'MpanCore',
  entityId: string,
  startDate: string,
  endDate: string,
  customerAccountReference: string,
  fileType: 'xml' | 'csv',
  requestType: Api.HHRequestType
): Promise<any> => {
  var consumptionUrl = `${url}/consumption/mpan-graph-hh-download?EntityType=${entityType}&EntityId=${entityId}&StartDateTime=${startDate}&EndDateTime=${endDate}&CustomerAccountReference=${customerAccountReference}&FileType=${fileType}`;
  if (requestType) {
    consumptionUrl += `&requestType=${requestType}`;
  }

  return await getSingleFile(consumptionUrl, {}, context);
};
