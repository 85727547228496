import { useContext, useState } from 'react';

// Styles
import { EnergyUsageWrapper } from '../energyusage.styles';

// Components
import { Loading, StyledTitle } from '../../../common/components';
import { UserDetailContext, UserDetailContextProps } from '../../../context/userDetail/userDetailContext';
import { AccountSelection } from './AccountSelection';
import { MpanSelection } from './MpanSelection';
import { sortAccountsByDisplay } from '../../../utils/sortFilters';
import { DateSelection } from './DateSelection';
import { getHHConsumptionData } from '../../../common/api/dataApi';
import { useAuthState } from '../../../providers/authProvider';
import { HHDataRequestSuccessModal } from '../../mymessages/modalTemplates/HHDataRequestSuccessModal';
import { HHDataRequestFailureModal } from '../../mymessages/modalTemplates/HHDataRequestFailureModal';
import { TagManager } from '../../utils/analytics/TagManager';
import { DataTypeSelection } from './DataTypeSelection';

const DownloadHHData = () => {
  const authContext = useAuthState();
  const { userDetail } = useContext<UserDetailContextProps>(UserDetailContext);
  const [accountRefSelected, setAccountRefSelected] = useState<Common.IOption>({ value: '', display: '' });
  const [dataTypeSelected, setDataTypeSelected] = useState<Common.IOption>({ value: '', display: '' });
  const [availableMpans, setAvailableMpans] = useState<Array<Common.IOption>>([]);
  const [totalMpansLength, setTotalMpansLength] = useState<number>(0);
  const [selectedMpans, setSelectedMpans] = useState<Array<Common.IOption>>([]);
  const [showHHRequestSuccessModal, setShowHHRequestSuccessModal] = useState<boolean>(false);
  const [showHHRequestFailureModal, setShowHHRequestFailureModal] = useState<boolean>(false);
  const [resetAccountData, setResetAccountData] = useState<boolean>(false);
  const [resetDataType, setResetDataType] = useState<boolean>(false);
  const [resetMpanData, setResetMpanData] = useState<boolean>(false);
  const [resetDateData, setResetDateData] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectMpansSelected, setSelectMpansSelected] = useState<'Individual' | 'All' | undefined>(undefined);
  const [metersArray, setMetersArray] = useState<Array<any>>([]);

  const accountDataOptions = userDetail.customerAccounts.map(item => ({
    value: item.essAccountID,
    display: `${item.essAccountID} - ${item.accountName}`,
  }));

  const handleAccountSelected = (option: Common.IOption, meterArray: any) => {
    setAccountRefSelected(option);
    setMetersArray(meterArray);
    setSelectedMpans([]);
    setDataTypeSelected({ value: '', display: '' });
    setResetDataType(!resetDataType);
    setResetMpanData(!resetMpanData);
    setResetDateData(!resetDateData);
  };

  const handleAccountMpans = (mpans: any) => {
    setAvailableMpans(mpans);
    setTotalMpansLength(mpans.length);
  };

  const handleAccountClear = () => {
    setResetAccountData(!resetAccountData);
    setResetDataType(!resetDataType);
    setResetMpanData(!resetMpanData);
    setResetDateData(!resetDateData);
    setSelectedMpans([]);
    setAvailableMpans([]);
    setTotalMpansLength(0);
    setDataTypeSelected({ value: '', display: '' });
  };

  const handleMpanClick = (option: Common.IOption) => {
    setSelectMpansSelected('Individual');
    let selectedMpansTemp = JSON.parse(JSON.stringify(selectedMpans));
    selectedMpansTemp.push(option);

    let availableMpansTemp = availableMpans;
    const index = availableMpansTemp.findIndex(c => c.value === option.value);
    if (index >= 0) {
      // Remove from available
      availableMpansTemp.splice(index, 1);
    }

    const selectedMpansSorted: any = sortAccountsByDisplay(selectedMpansTemp);
    const availableMpansSorted: any = sortAccountsByDisplay(availableMpansTemp);

    setAvailableMpans(availableMpansSorted);
    setSelectedMpans(selectedMpansSorted);
  };

  const handleMpanClickArray = (option: Array<Common.IOption>) => {
    let selectedMpansTemp = JSON.parse(JSON.stringify(selectedMpans));
    let availableMpansTemp = availableMpans;

    for (let i = 0; i < option.length; i++) {
      const index = availableMpansTemp.findIndex(c => c.value === option[i].value);
      if (index >= 0) {
        // Add back to Selected
        selectedMpansTemp.push(option[i]);
      }
    }
    availableMpansTemp = [];

    const selectedMpansSorted: any = sortAccountsByDisplay(selectedMpansTemp);
    const availableMpansSorted: any = sortAccountsByDisplay(availableMpansTemp);

    setAvailableMpans(availableMpansSorted);
    setSelectedMpans(selectedMpansSorted);
  };

  const handleTrash = (option: Common.IOption) => {
    let selectedMpansTemp = selectedMpans;
    const index = selectedMpansTemp.findIndex(c => c.value === option.value);
    if (index >= 0) {
      // Remove from Selected
      selectedMpansTemp.splice(index, 1);

      // Add back to Available
      let availableMpansTemp = JSON.parse(JSON.stringify(availableMpans));
      availableMpansTemp.push(option);

      const selectedMpansSorted: any = sortAccountsByDisplay(selectedMpansTemp);
      const availableMpansSorted: any = sortAccountsByDisplay(availableMpansTemp);

      setAvailableMpans(availableMpansSorted);
      setSelectedMpans(selectedMpansSorted);
    }
  };

  const handleTrashArray = (option: Array<Common.IOption>) => {
    let selectedMpansTemp = selectedMpans;
    let availableMpansTemp = JSON.parse(JSON.stringify(availableMpans));

    for (let i = 0; i < option.length; i++) {
      const index = selectedMpansTemp.findIndex(c => c.value === option[i].value);
      if (index >= 0) {
        // Add back to Available
        availableMpansTemp.push(option[i]);
      }
    }
    selectedMpansTemp = [];
    const selectedMpansSorted: any = sortAccountsByDisplay(selectedMpansTemp);
    const availableMpansSorted: any = sortAccountsByDisplay(availableMpansTemp);

    setAvailableMpans(availableMpansSorted);
    setSelectedMpans(selectedMpansSorted);
  };

  const handleConfirmClick = async (startDate: any, endDate: any) => {
    const mpans = selectedMpans.map((item: Common.IOption) => {
      return item.value;
    });

    const requestType = dataTypeSelected.value as Api.HHRequestType;

    TagManager.pushData({
      event: 'HH data request confirm',
      account: accountRefSelected.display,
      startDate: startDate.format('DD/MM/YYYY'),
      endDate: endDate.format('DD/MM/YYYY'),
      mpanOption: selectMpansSelected,
      count: mpans.length,
      requestType,
    });

    const result: any = await getHHConsumptionData(
      process.env.REACT_APP_API_URL,
      accountRefSelected.value,
      mpans,
      startDate,
      endDate,
      requestType,
      authContext
    );

    if (!result) {
      setShowHHRequestFailureModal(true);
    } else if (result && result.status == '500') {
      setShowHHRequestFailureModal(true);
    } else if (result && result.status == '200') {
      setShowHHRequestSuccessModal(true);
    } else {
      setShowHHRequestFailureModal(true);
    }
    handlePageReset();
  };

  const handleDataTypeSelected = (option: Common.IOption) => {
    setDataTypeSelected(option);
    setSelectedMpans([]);
    setResetMpanData(!resetMpanData);
    setResetDateData(!resetDateData);
  };

  const clearSelectedMpans = () => {
    handleTrashArray(selectedMpans);
  };

  const handleSelectAllMpans = () => {
    setSelectMpansSelected('All');
    handleMpanClickArray(availableMpans);
  };
  const handleLoadingData = (loading: boolean) => {
    setIsLoading(loading);
  };

  const handlePageReset = () => {
    setAccountRefSelected({ value: '', display: '' });
    setMetersArray([]);
    setAvailableMpans([]);
    setSelectedMpans([]);
    setResetAccountData(!resetAccountData);
    setResetDataType(!resetDataType);
    setResetMpanData(!resetMpanData);
    setResetDateData(!resetDateData);
    setTotalMpansLength(0);
  };

  return (
    <EnergyUsageWrapper>
      <StyledTitle>Download HH data</StyledTitle>

      <div className='downloadHhPage pt-5'>
        <AccountSelection
          accountData={accountDataOptions}
          handleAccountSelected={handleAccountSelected}
          handleAccountMpans={handleAccountMpans}
          resetData={resetAccountData}
          handleAccountClear={handleAccountClear}
          handleLoadingData={handleLoadingData}
        />
        {metersArray.length > 0 && (
          <DataTypeSelection
            accountRefSelected={accountRefSelected}
            metersArray={metersArray}
            handleDataTypeSelected={handleDataTypeSelected}
            handleAccountMpans={handleAccountMpans}
            handleLoadingData={handleLoadingData}
            resetData={resetDataType}
          />
        )}
        {dataTypeSelected.value !== '' && (
          <div className='d-flex-column'>
            <div className='mt-2 mb-4'>
              <p>You can download up to 12 consecutive months of half-hourly consumption data.</p>
              <p>
                Depending on the size of your request, it could take us up to 24 hours to deliver the data to you.
                <br />
                We'll email you when the data's available.
              </p>
              <p className='font-italic'>
                Note that you can only have one active request associated with your account. Once we've delivered the
                data you've requested, you'll be able to raise another request.
              </p>
            </div>
          </div>
        )}
        {dataTypeSelected.value !== '' && (
          <MpanSelection
            availableMpans={availableMpans}
            totalMpansLength={totalMpansLength}
            selectedMpans={selectedMpans}
            meterDataType={dataTypeSelected}
            dropdownDisabled={accountRefSelected.value === '' || availableMpans.length < 1}
            handleMpanClick={handleMpanClick}
            handleTrash={handleTrash}
            handleSelectAllMpans={handleSelectAllMpans}
            clearSelectedMpans={clearSelectedMpans}
            resetData={resetMpanData}
          />
        )}
        {dataTypeSelected.value !== '' && (
          <DateSelection
            handleConfirmClick={handleConfirmClick}
            mpansAvailable={selectedMpans.length > 0}
            resetData={resetDateData}
          />
        )}

        <HHDataRequestSuccessModal
          meterType={dataTypeSelected}
          show={showHHRequestSuccessModal}
          onHide={() => {
            setShowHHRequestSuccessModal(false);
            setDataTypeSelected({ value: '', display: '' });
          }}
        ></HHDataRequestSuccessModal>

        <HHDataRequestFailureModal
          meterType={dataTypeSelected}
          show={showHHRequestFailureModal}
          onHide={() => {
            setShowHHRequestFailureModal(false);
            setDataTypeSelected({ value: '', display: '' });
          }}
        ></HHDataRequestFailureModal>
      </div>
      {isLoading && <Loading overlay />}
    </EnergyUsageWrapper>
  );
};

export default DownloadHHData;
